export const debounce = <T extends (...args: any[]) => any>(callback: T, waitTime: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>): void => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, waitTime);
  };
};
