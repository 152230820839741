import { Mutation, VariantStockDataArgs } from 'service/types';
import { removeCartItemMutation } from 'service/mutations/cart';
import { apiMutate } from 'service/utils/mutation';
import ServiceConfigs from 'service/config';
import { ORDER_TIME_TYPES } from 'common/constants';
import ErrorsFactory from 'common/lib/errorHandler/errors';
import { ErrorCodes } from 'common/lib/errorHandler/enums';
import { RenderingRegistry } from 'common/lib/registries';
import { useUpdateCartValues } from './utils';
import {
  useGlobalOrderTypeAndTime,
  usePersistedAppliedVoucherCode,
  usePersistedCartData,
  usePersistedVoucherError,
} from '../../../common/hooks';
import { useVerifyVoucher } from '../voucher';

type useRemoveCartItemType = ({
  cartId,
  isCartAuthorized,
}: {
  cartId: string;
  isCartAuthorized?: boolean;
}) => (cartItemId: string, scheduledTime: VariantStockDataArgs['scheduledTime']) => Promise<void | { hasError: boolean }>;

const useRemoveCartItem: useRemoveCartItemType = ({ cartId, isCartAuthorized }) => {
  const updateCartValues = useUpdateCartValues({ cartId, isCartAuthorized });
  const [, , removeCartData] = usePersistedCartData();
  const verifyVoucher = useVerifyVoucher();
  const [, setVoucherErrorMessage] = usePersistedVoucherError();
  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const [appliedVoucherCode, ,] = usePersistedAppliedVoucherCode();
  const { fulfillmentTimeType } = globalOrderTypeAndTime || {};
  const isScheduled = fulfillmentTimeType === ORDER_TIME_TYPES.SCHEDULE;
  const isManualOrder = RenderingRegistry.getIsManualOrder();

  const verifyAndUpdateVoucher = async () => {
    const { valid, code } = await verifyVoucher(appliedVoucherCode, isScheduled, cartId);
    if (valid) {
      setVoucherErrorMessage('');
    } else {
      const errorDetails = ErrorsFactory.getError(code as ErrorCodes);
      setVoucherErrorMessage(`errors:${errorDetails?.errorDescription}`);
    }
  };

  return (cartItemId, scheduledTime) =>
    apiMutate({
      mutation: cartId && cartItemId ? removeCartItemMutation : null,
      variables: {
        cartId,
        cartItemId,
        ...(!!scheduledTime && { scheduledTime }),
        isManualOrder,
      },
      requestHeaders: isCartAuthorized ? { ...ServiceConfigs.getAuthorizationHeader() } : {},
      handler: (res: Mutation) => res?.deleteItemFromCart?.cart,
    }).then(async cart => {
      if (cart) {
        await updateCartValues(cart);
        if (appliedVoucherCode) await verifyAndUpdateVoucher();
      } else {
        await removeCartData();
      }
      return { hasError: !cart };
    });
};

export default useRemoveCartItem;
