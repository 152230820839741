import { FULFILLMENT_MODES, URLS } from 'common/constants';
import { changeRoute } from 'common/utils';
import { DateTime } from 'luxon';
import { isBranchBusyAtDateTime } from 'modules/checkoutModule/utils';
import { Branch, Cart } from 'service/types';

type HandleBusyBranchQuickCartProps = {
  addItemToCartHandler: () => Promise<void | Partial<Cart> | { hasError: boolean }>;
  branch: Branch;
  mode: string;
  t: (key: string) => string;
  scheduleOrdersEnabled: boolean;
  updateModal: (modalProps: any) => void;
};

export const handleBusyBranchQuickCart = async ({
  addItemToCartHandler,
  branch,
  mode,
  t,
  scheduleOrdersEnabled,
  updateModal,
}: HandleBusyBranchQuickCartProps): Promise<boolean> => {
  const { busyMode, pickupEnabled, busyFrom, busyUntil } = branch || {};
  const isPickupBusy = isBranchBusyAtDateTime(DateTime.now(), FULFILLMENT_MODES.PICKUP, busyMode, busyFrom, busyUntil);

  const getConfirmButtonText = () => {
    if (mode === FULFILLMENT_MODES.PICKUP) {
      return t('branches:continue');
    }
    if (scheduleOrdersEnabled) {
      return t('branches:branchBusy.scheduleOrder');
    }
    return t('branches:continue');
  };

  return new Promise(resolveItemAddedToCart => {
    const handleConfirm = async () => {
      await addItemToCartHandler();
      if (scheduleOrdersEnabled) {
        changeRoute({ pathname: URLS.ESTIMATED_TIME });
      }
      updateModal({ isOpen: false });
      resolveItemAddedToCart(true);
    };

    const switchToPickup = () => {
      const route = { pathname: URLS.ORDER_MODE, query: { mode: FULFILLMENT_MODES.PICKUP } };
      changeRoute(route);
      updateModal({ isOpen: false });
      resolveItemAddedToCart(false);
    };

    const modalConfig = {
      isOpen: true,
      showCloseIcon: false,
      title: t('branches:branchBusy.title'),
      description: t('branches:branchBusy.description'),
      confirmButtonText: getConfirmButtonText(),
      ...(!(isPickupBusy || !pickupEnabled) && {
        cancelButtonText: t('branches:branchBusy.switchToPickup'),
        onCancel: switchToPickup,
      }),
      onConfirm: handleConfirm,
    };

    updateModal(modalConfig);
  });
};
