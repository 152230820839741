import React, { useState } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@zydalabs/zac-react';
import { DEBOUNCE_RATE, LANGUAGE_AR, ORDER_TIME_TYPES } from 'common/constants';
import { debounce, normalizeFulfillmentMode } from 'common/utils';
import {
  useGlobalInitializingCart,
  useGlobalOrderTypeAndTime,
  usePersistedBranchId,
  usePersistedCountryDetails,
  usePersistedSupportedDeliveryZone,
  useUserData,
} from 'common/hooks';
import { CustomActionsRegistry } from 'common/lib/registries';
import { CustomActionsEnum } from 'common/enums';
import { handleBusyBranchQuickCart, trackAddToCartEvents, translateDisplayPrice } from 'modules/productsModule/utils';
import { useAddToCart, useRemoveFromCart, useUpdateItemInCart } from 'modules/productsModule/hooks';
import ServiceConfigs from 'service/config';
import { ShoppingBagIcon } from '@zydalabs/zac-icons-react';
import { useCreateFacebookCapi, useStoreSettings } from 'service/hooks';
import { useUpdateModal } from '@zydalabs/storefront-components';
import { isBranchBusyAtDateTime } from 'modules/checkoutModule/utils';
import { DateTime } from 'luxon';
import { StyledCounterWrapper } from './style';
import { CardButtonPropType } from './types';
import Counter from '../Counter';
import { useFulfillmentSettings } from '../../../../contexts';

const CardButton: React.FC<CardButtonPropType> = ({
  uuid,
  cartItemId,
  isQuickAddToCartEnabled,
  mode,
  variantQuantity,
  firstVariantId = '',
  firstVariantName = '',
  firstVariantPrice = 0.0,
  stockCount,
  displayPrice,
  isProductUnAvailable,
  setShouldInvalidateProduct,
  price,
  highestDiscountedPercentage,
  title,
  isFoodCard,
}) => {
  const router = useRouter();
  const { t, lang } = useTranslation('product');
  const updateModal = useUpdateModal();
  const [count, setCount] = useState<number>(variantQuantity);
  const useDeliveryzonesMs = ServiceConfigs?.getUseDeliveryzonesMs();
  const { area, branch } = useFulfillmentSettings();
  const { busyMode, busyFrom, busyUntil } = branch || {};
  const isPresistedModeBusy = isBranchBusyAtDateTime(DateTime.now(), mode, busyMode, busyFrom, busyUntil);
  // persisted data
  const [persistedCountryDetails] = usePersistedCountryDetails();
  const [supportedDeliveryZone] = usePersistedSupportedDeliveryZone();
  const userData = useUserData();
  const [globalOrderTypeAndTime] = useGlobalOrderTypeAndTime();
  const { fulfillmentTimeType } = globalOrderTypeAndTime || {};
  const isScheduled = fulfillmentTimeType === ORDER_TIME_TYPES.SCHEDULE;

  const [branchId] = usePersistedBranchId();

  const [isInitializingCart] = useGlobalInitializingCart();

  const currency = persistedCountryDetails?.currency?.[`title_${router.locale}`];
  const currencyEn = persistedCountryDetails?.currency?.title_en;
  const isArabic = router.locale === LANGUAGE_AR;
  const { data: storeSettings } = useStoreSettings();
  const scheduleOrdersEnabled = storeSettings?.setting?.scheduleOrdersEnabled ?? false;
  // mutations
  const addItemToCart = useAddToCart({
    branchId,
    deliveryType: normalizeFulfillmentMode(mode),
    // TODO :: Check if areaID should be sent conditionally
    areaId: area?.id,
    ...(useDeliveryzonesMs &&
      !!supportedDeliveryZone && {
        deliveryZone: {
          ...supportedDeliveryZone,
        },
      }),
  });
  const updateCartItem = useUpdateItemInCart();
  const removeCartItem = useRemoveFromCart();
  const createCapiEvent = useCreateFacebookCapi();

  const getDiscountedPrice = (fullPrice, discountPercentage) => {
    if (!discountPercentage) return fullPrice;
    return fullPrice - fullPrice * discountPercentage * 0.01;
  };

  const handleUpdateRemoveCartItem = async newCount => {
    const isRemoving = newCount < count;
    setCount(newCount);
    const quickUpdateRemoveCartItemCallback = async () => {
      const itemPrice =
        (price && getDiscountedPrice(price, highestDiscountedPercentage)) ||
        (firstVariantPrice && getDiscountedPrice(firstVariantPrice, highestDiscountedPercentage));
      CustomActionsRegistry.setCustomAction({
        key: CustomActionsEnum.MutateCartDetails,
        fn: () => setShouldInvalidateProduct(true),
      });
      if (newCount === 0) {
        await removeCartItem(cartItemId);
      } else if (!variantQuantity && !!newCount) {
        const addItemToCartHandler = () =>
          addItemToCart({
            menuItemId: uuid,
            variantQuantity: newCount,
            variantId: firstVariantId,
          });
        if (isPresistedModeBusy && !isScheduled) {
          const itemAddedToCart = await handleBusyBranchQuickCart({
            addItemToCartHandler,
            branch,
            mode,
            t,
            scheduleOrdersEnabled,
            updateModal,
          });
          if (!isRemoving && itemAddedToCart) {
            trackAddToCartEvents(uuid, itemPrice, title, firstVariantName, createCapiEvent, userData, currencyEn);
          }
          return;
        }
        await addItemToCartHandler();
      } else await updateCartItem({ cartItemId, menuItemId: uuid, variantId: firstVariantId, variantQuantity: newCount });
      if (newCount !== 0 && !isRemoving) {
        trackAddToCartEvents(uuid, itemPrice, title, firstVariantName, createCapiEvent, userData, currencyEn);
      }
    };
    const debouncedQuickCart = debounce(quickUpdateRemoveCartItemCallback, DEBOUNCE_RATE);
    debouncedQuickCart();
  };

  return (
    <>
      {count > 0 && isQuickAddToCartEnabled && (
        <StyledCounterWrapper
          onClick={e => {
            e.stopPropagation();
          }}
          isFoodCard={isFoodCard}
        >
          <Counter
            count={count}
            onChange={newCount => {
              handleUpdateRemoveCartItem(newCount);
            }}
            isLoading={isInitializingCart}
            disabled={isProductUnAvailable || isInitializingCart}
            maxCount={stockCount}
          />
        </StyledCounterWrapper>
      )}
      {!(isQuickAddToCartEnabled && count) && (
        <Button
          variant="tertiary"
          isFluid={!isFoodCard}
          text={displayPrice && translateDisplayPrice(displayPrice, t, lang, currency, isArabic)}
          isDisabled={isProductUnAvailable || isInitializingCart}
          onClick={e => {
            if (isQuickAddToCartEnabled) {
              e.stopPropagation();
              handleUpdateRemoveCartItem(1);
            }
          }}
          endIcon={!!isQuickAddToCartEnabled && <ShoppingBagIcon width={20} />}
          size="small"
          testId="card-action-btn"
        />
      )}
    </>
  );
};

export default CardButton;
