import { MutationCapiTrackEventArgs } from 'service/types';
import { pushAddToCartEventToDataLayer } from 'common/lib/tracking/utils';
import { trackTiktokEvents } from 'common/utils/trackTiktokEvents';
import { trackSnapchatEvents } from 'common/utils/trackSnapchatEvents';
import { DATA_LAYER_CONTENT_TYPE, FACEBOOK_PIXEL_EVENTS, SNAPCHAT_EVENTS, TIKTOK_EVENTS } from 'common/constants';
import { trackFacebookEvent } from 'common/utils/trackFacebookEvents';
import { UserDataReturnType } from 'common/hooks/useUserData';

export const trackAddToCartEvents = (
  uuid: string,
  itemPrice: number,
  title: string,
  firstVariantName: string,
  createCapiEvent: (variables: Partial<MutationCapiTrackEventArgs>) => Promise<void>,
  userData: UserDataReturnType,
  currencyEn: string,
): void => {
  const { userDataBackEnd, capiEnabled, tiktokData } = userData;

  trackFacebookEvent(
    FACEBOOK_PIXEL_EVENTS.ADD_TO_CART,
    {
      content_ids: uuid,
      content_type: DATA_LAYER_CONTENT_TYPE.PRODUCT,
      currency: currencyEn,
      value: itemPrice || 0,
      content_name: title,
    },
    userDataBackEnd,
    createCapiEvent,
    capiEnabled,
  );

  trackSnapchatEvents(SNAPCHAT_EVENTS.ADD_TO_CART, {
    currency: currencyEn,
    price: itemPrice || 0,
    content_name: title,
    item_ids: [uuid],
    item_category: title,
    number_items: 1,
  });

  trackTiktokEvents(
    TIKTOK_EVENTS.ADD_TO_CART,
    {
      currency: currencyEn,
      value: itemPrice || 0,
      content_type: 'product',
      content_id: uuid,
      quantity: 1,
    },
    tiktokData,
  );

  pushAddToCartEventToDataLayer({
    contents: [{ item_id: uuid, quantity: 1, item_variant: firstVariantName, item_name: title }],
    content_type: DATA_LAYER_CONTENT_TYPE.PRODUCT,
    currency: currencyEn,
    value: itemPrice || 0,
  });
};
